import { combineReducers } from 'redux';
import common from './common';
import session from './session';
import taxonomyReducer from './taxonomies/TaxonomyReducer';
import datasetSubmissionsReducer from './datasetSubmissions/datasetSubmissionsReducer';
import benchmarkSegmentReducer from './benchmarkSegment/benchmarkSegment';
import reportSpecificationReducer from './reportSpecification/reportSpecification';
import settingsReducer from './settings/SettingsReducer';
import dpSettingsReducer from './dpSettings/DPSettingsReducer';
import benchmarkDeliveriesReducer from './benchmarkDeliveries/BenchmarkDeliveriesReducer';
import validationResultsReducer from './validationResults/ValidationResultsReducer';

export default combineReducers({
  common,
  taxonomyReducer,
  session,
  dpSettingsReducer,
  datasetSubmissionsReducer,
  benchmarkSegmentReducer,
  reportSpecificationReducer,
  settingsReducer,
  benchmarkDeliveriesReducer,
  validationResultsReducer
});
