import {
  SET_REPORT_COMPONENT_DATA,
  SET_BINDABLE_ELEMENT_DATA
} from 'constants/reportSpecifications';

const initialState = {
  reportComponentList: [],
  reportComponentData: { bindableComponents: [], totalElements: 0, number: 0, reportId: '' },
  bindableElementList: [],
  bindableElementsData: { bindableElements: [], totalElements: 0, number: 0, reportId: '' }
};

export default function reportSpecificationReducer(state = initialState, action) {
  switch (action.type) {
    case SET_REPORT_COMPONENT_DATA:
      return { ...state, reportComponentData: action.payload };
    case SET_BINDABLE_ELEMENT_DATA:
      return { ...state, bindableElementsData: action.payload };
    default:
      return state;
  }
}
