import React from 'react';
import styled from 'styled-components';
import { COLORS } from 'theme/colors';
import { useSelector } from 'react-redux';

function Spinner({ defaultSpinner = false }) {
  const { apiCounter } = useSelector((state) => state.common);
  const { spinnerText } = useSelector((state) => state.common);
  const showSpinner = defaultSpinner || apiCounter > 0;
  return showSpinner ? (
    <SpinnerDiv data-ta="spinner">
      <StyledSpinner className="fad fa-spinner-third fa-spin fa-3x" />
      {spinnerText && <StyledSpinnerText>{spinnerText}</StyledSpinnerText>}
    </SpinnerDiv>
  ) : null;
}

export default React.memo(Spinner);

const StyledSpinnerText = styled.div`
  margin-top: 1rem;
  margin-left: 45%;
`;

const StyledSpinner = styled.i`
  --fa-secondary-color: ${COLORS.APP_THEME_BLUE};
  --fa-primary-opacity: 0.2;
  --fa-secondary-opacity: 1;
  margin-top: 22%;
  left: 47%;
`;

const SpinnerDiv = styled.div`
  display: inline-block;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 10;
  opacity: 0;
  animation-name: fadeIn;
  animation-delay: 2s;
  animation-fill-mode: forwards;
  .fa-spin {
    -webkit-animation: fa-spin 0.8s infinite linear;
    animation: fa-spin 0.8s infinite linear;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
`;
