/* eslint-disable import/prefer-default-export */
import React from 'react';
import { sessionService } from 'redux-react-session';
import { store } from 'index';
import { showFlashMessage } from 'actions/common';
import {
  BASE_URL_NAME,
  DATE_FORMAT,
  FOURWEEK,
  MAX_VALUE_OF_DOUBLE_TYPE,
  MIN_VALUE_OF_DOUBLE_TYPE,
  MONTH,
  WEEK,
  DAY
} from 'constants/Common';
import styled, { css } from 'styled-components';
import { COLORS } from 'theme/colors';
import { Icon } from 'lib/icon/icon';
import { Tooltip } from 'lib/tooltip/tooltip';
import i18next from 'i18next';
import { dateTimeFormats, VPLocalizedDisplayDate } from '@visionplanner/vp-ui-react-l10n';
import enCountryList from 'i18n/countries/en';
import nlCountryList from 'i18n/countries/nl';

const PARSER = require('cron-parser');

const INTEGER_PATTERN = /^(-?\d+)?$/;
const FLOAT_PATTERN = /^(-?\d+)?(\d\.(\d+)?)?$/;

export const STATES = {
  development: 'Development',
  processing: 'Processing',
  published: 'Published',
  modification: 'Modification',
  modificationPending: 'Modification_Pending',
  deletion: 'Deletion',
  deletionPending: 'deletion_pending',
  invalid: 'Invalid',
  cached: 'Cached',
  processed: 'Processed',
  calculating: 'Calculating',
  valid: 'Valid',
  enriched: 'Enriched',
  uploaded: 'Uploaded'
};

export const getTranslatedState = (state) => {
  if (state) {
    switch (state.toUpperCase()) {
      case STATES.processed.toUpperCase():
        return i18next.t('dataset-submissions.status.processed');
      case STATES.invalid.toUpperCase():
        return i18next.t('dataset-submissions.status.invalid');
      case STATES.valid.toUpperCase():
        return i18next.t('dataset-submissions.status.valid');
      case STATES.uploaded.toUpperCase():
        return i18next.t('dataset-submissions.status.uploaded');
      case STATES.calculating.toUpperCase():
        return i18next.t('dataset-submissions.status.calculating');
      case STATES.enriched.toUpperCase():
        return i18next.t('dataset-submissions.status.enriched');
      default:
        return state;
    }
  }
  return null;
};

export function getSessionDetails() {
  return sessionService.loadUser().then(({ userOrganizationList = [] }) => {
    if (userOrganizationList[0]) {
      return { ...userOrganizationList[0] };
    }
    return {};
  });
}

export const formatDate = ({ date = null, withMeridiem = false, format = DATE_FORMAT }) => {
  if (date) {
    const displayFormat = withMeridiem ? dateTimeFormats.MEDIUM : format;
    return <VPLocalizedDisplayDate date={date} format={displayFormat} />;
  }
  return '-';
};

const format = () => (i18next.language === 'en' ? 'en-US' : 'nl-NL');

export const removeBrackets = (name) => {
  if (name) {
    return name.replace(/\)/g, '!cb!');
  }
  return null;
};

export const addBrackets = (name) => {
  if (name) {
    return name.replace(/!cb!/g, ')');
  }
  return null;
};

const formatIndicators = () => {
  const displayFormat = format();
  return {
    grouping: (1111).toLocaleString(displayFormat).replace(/1/g, ''),
    decimal: (1.1).toLocaleString(displayFormat).replace(/1/g, '')
  };
};

const shouldShowDecimalValues = (maximumFractionDigits = 20) => maximumFractionDigits > 0;

export const isValid = (parsedValue, maximumFractionDigits = 20) => {
  const regex = shouldShowDecimalValues ? FLOAT_PATTERN : INTEGER_PATTERN;
  const values = parsedValue.split('.', 2);
  return (
    regex.test(parsedValue) && (values.length <= 1 || values[1].length <= maximumFractionDigits)
  );
};

const numberFormatter = (maxDecimal = 20) => {
  const displayFormat = format();
  return new Intl.NumberFormat(displayFormat, {
    useGrouping: true, // if true the number is formatted to display thousand and million seperator
    maximumFractionDigits: maxDecimal
  });
};

export const parseFromFormat = (fieldValue) => {
  const indicators = formatIndicators();
  // Remove grouping indicators
  const valueAfterGroupingRemoval = fieldValue
    .toString()
    .replace(new RegExp(`\\${indicators.grouping}`, 'g'), '');
  // Swap decimal indicator with .
  return valueAfterGroupingRemoval.replace(new RegExp(`\\${indicators.decimal}`, 'g'), '.');
};

export const convertToFormat = (fieldValue, maxDecimal = 20) => {
  if (fieldValue === '-') {
    return fieldValue;
  }
  const indicators = formatIndicators();
  const endsWithDecimalIdentifier = fieldValue.toString().endsWith('.');
  const formatter = numberFormatter(maxDecimal);
  // The format function discards the trailing decimal identifier "." similar to parseFloat. So adding that at the end of the formatted number
  const formattedValue = endsWithDecimalIdentifier
    ? `${formatter.format(parseFromFormat(fieldValue))}${indicators.decimal}`
    : formatter.format(fieldValue);
  // An empty string is formatted as 0, which displays a 0 in the input initially. Added this check to eliminate this and display a 0 only when it is entered.
  return fieldValue === '' && formattedValue === '0' ? '' : formattedValue;
};

export const parseNumber = (parsedValue) => {
  const number = parseFloat(parsedValue);
  if (Number.isNaN(number)) {
    return null;
  }
  return number;
};

export const statusWithImages = {
  development: { name: 'utils.states.development', color: COLORS.CORAL_REEF_GREY },
  processing: { name: 'utils.states.processing', color: COLORS.BRIGHT_YELLOW },
  published: { name: 'utils.states.published', color: COLORS.LIGHT_GREEN },
  available: { name: 'utils.states.available', color: COLORS.LIGHT_GREEN },
  modification: {
    name: 'utils.states.modificationPending',
    color: COLORS.CORAL_REEF_GREY
  },
  modification_pending: {
    name: 'utils.states.modificationPending',
    color: COLORS.CORAL_REEF_GREY
  },
  deletion: { name: 'utils.states.deletionPending', color: COLORS.DARK_PINK },
  deletion_pending: { name: 'utils.states.deletionPending', color: COLORS.DARK_PINK },
  invalid: { name: 'utils.states.invalid', color: COLORS.DARK_PINK },
  cached: { name: 'utils.states.cached', color: COLORS.PERIWINKLE_GRAY }
};

export function StatusIcon(props) {
  const { color } = props;
  return <StyledStatusIcon name="circle" iconSet="fas" colorCode={color} />;
}

export function DeprecatedIcon(props) {
  const { id, overlay } = props;
  return (
    <Tooltip key={id} overlay={overlay} placement="bottom" showTipPointer="false">
      <StyledDiv>
        <StyledDeprecationIcon iconSet="fa fa-exclamation-triangle" />
      </StyledDiv>
    </Tooltip>
  );
}

export function CheckIcon(props) {
  return <Icon name="check" iconSet="fal fa-1x" {...props} />;
}

export function isString(string) {
  return typeof string === 'string';
}

export function dispatchFlashMessage({
  successMessage = '',
  isIntl = false,
  errorMessage = '',
  progressMessage = ''
}) {
  store.dispatch(
    showFlashMessage({
      isIntl,
      successMessage,
      errorMessage,
      progressMessage
    })
  );
}

export function parseJSONFile(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = () => reject(new Error({ success: false }));
    reader.onload = function (e) {
      // eval parses the unquoted json and returns a parsed json
      try {
        // Disabling eval for only this line to prevent unnecessary use else where
        // eslint-disable-next-line no-eval
        resolve({ data: JSON.stringify(eval(`(${e.target.result})`)), success: true }); // NOSONAR
      } catch (err) {
        reject(new Error({ success: false }));
      }
    };
    reader.readAsText(file);
  });
}

export async function parseJSONAndCreateAFile(file) {
  try {
    const { data } = await parseJSONFile(file);
    const blob = new Blob([data], { type: 'application/json' });
    const newFile = new File([blob], 'template.json');
    return { file: newFile, success: true };
  } catch (e) {
    return { success: false };
  }
}

export function parseHTMLFile(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = () => reject(new Error({ success: false }));
    reader.onload = function (e) {
      try {
        resolve({ data: `${e.target.result}`, success: true });
      } catch (err) {
        reject(new Error({ success: false }));
      }
    };
    reader.readAsText(file);
  });
}

export function sortArrayOfObjects({ data, key, isAsc }) {
  return data.sort((a, b) =>
    isAsc
      ? a[key] && b[key] && a[key].toString().localeCompare(b[key])
      : a[key] && b[key] && -a[key].toString().localeCompare(b[key])
  );
}

export function setTooltipText(data) {
  if (isString(data)) return data;
  // const isHtml = /<\/?[a-z][\s\S]*>/i.test(data);
  // if (isHtml) {
  //   const innerText = data.innerHTML;
  //   return isString(innerText) ? innerText : null;
  // }
  return data && data.props ? data.props.text : null;
}

export function setInviteRedirectUrl() {
  const baseUrl = BASE_URL_NAME.length ? `${BASE_URL_NAME}/` : '';
  return `${process.env.REACT_APP_WEB_PORTAL_URL}${baseUrl}`;
}

export function renderWithMinMaxCheck(value) {
  if (value === MIN_VALUE_OF_DOUBLE_TYPE) return '(min)';
  else if (value === MAX_VALUE_OF_DOUBLE_TYPE) return '(max)';
  return value;
}

const StyledStatusIcon = styled(Icon)`
  ${(props) =>
    css`
      color: ${props.colorCode};
      font-size: 9px;
    `}
`;
export const MinusIcon = styled(Icon)`
  width: 6px;
`;
export const CenterText = styled.div`
  text-align: center;
`;

export const range = (start, end, step) =>
  Array.from(Array.from(Array(Math.ceil((end - start) / step)).keys()), (x) => start + x * step);

export const StyledDeprecationIcon = styled(Icon)`
  color: ${COLORS.RED};
  margin-left: 5px;
  height: 12px;
  font-size: 14px;
  transform: translateY(-2px);
`;

const StyledDiv = styled.div`
  display: inline-block;
`;

export const convertNumberWithFormat = (value = 'NaN', maximumFractionDigits = 20) => {
  let formattedValue = renderWithMinMaxCheck(value);
  if (isNaN(formattedValue)) {
    return formattedValue;
  }
  if (i18next.language === 'en') {
    formattedValue = new Intl.NumberFormat('en-US', {
      maximumFractionDigits
    }).format(value);
  } else {
    formattedValue = new Intl.NumberFormat('nl-NL', {
      maximumFractionDigits
    }).format(value);
  }
  return formattedValue;
};

export const getLastYear = () => {
  const date = new Date();
  return date.getFullYear() - 1;
};

const formatExpression = ({ intervalUnit, day, hour, minutes }) => {
  switch (intervalUnit) {
    case WEEK:
      return hour ? `* ${minutes} ${hour} * * ${day}` : `* * * * * ${day}`;
    case MONTH:
    case FOURWEEK:
      return hour ? `* ${minutes} ${hour} ${day} * *` : `* * * ${day} * *`;
    case DAY:
      return hour ? `* ${minutes} ${hour} * * *` : `* * * * * *`;
    default:
      return null;
  }
};

const formatWeekDay = (day) => (day === 0 ? 7 : day);

export const getUTCTime = ({ intervalUnit, minutes = 0, hour = 0, day = 0 }) => {
  if (minutes < 60 && hour < 24 && day < 31) {
    const expression = formatExpression({ intervalUnit, day, hour, minutes });
    const interval = expression !== null ? PARSER.parseExpression(expression) : null;
    const utcDate = interval !== null ? new Date(interval.next().toString()) : null;
    return {
      utcDay:
        intervalUnit === WEEK
          ? formatWeekDay(utcDate.getUTCDay())
          : intervalUnit === DAY
            ? 1
            : utcDate !== null
              ? utcDate.getUTCDate()
              : null,
      utcHours: hour ? (utcDate !== null ? utcDate.getUTCHours() : null) : null,
      utcMinutes: hour ? (utcDate !== null ? utcDate.getUTCMinutes() : null) : null
    };
  }
  return { utcDay: null, utcHours: null, utcMinutes: null };
};

export const getLocalTime = ({ intervalUnit, time, day }) => {
  const hour = time ? Math.floor(time / 60) : 0;
  const minutes = time ? time % 60 : 0;
  if (minutes < 60 && hour < 24 && day < 31) {
    const expression = formatExpression({ intervalUnit, day, hour, minutes });
    const interval = PARSER.parseExpression(expression, { tz: 'UTC' });
    const localDate = new Date(interval.next().toString());
    return {
      localDay: intervalUnit === WEEK ? formatWeekDay(localDate.getDay()) : localDate.getDate(),
      localHours: localDate.getHours().toString().padStart(2, '0'),
      localMinutes: localDate.getMinutes().toString().padStart(2, '0')
    };
  }
  return { localDay: null, localHours: null, localMinutes: null };
};

export const getCountriesData = () => (i18next.language === 'nl' ? nlCountryList : enCountryList);
