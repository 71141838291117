import React, { PureComponent, Suspense } from 'react';
import ErrorBoundary from 'components/ErrorBoundary';
import { BrowserRouter as Router } from 'react-router-dom';
import { fetchUserOrganizationInfo, saveSessionDataToStore } from 'actions/session/sessionActions';
import { storeImageData, selectI18nLanguage } from 'actions/common';
import { BASE_URL_NAME, ENGLISH, DUTCH } from 'constants/Common';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Spinner from 'components/common/Spinner';
import i18n from 'i18n/i18nConfig';
import { L10NProvider } from '@visionplanner/vp-ui-react-l10n';

const AppResolver = React.lazy(() => import('./AppResolver'));

// TODO:: Make Logo loading async, remove user related data from common redux store
class App extends PureComponent {
  state = {
    userRolesLoaded: false
  };

  async componentDidMount() {
    this.setCurrentLanguage();

    const { storeImageData, saveSessionDataToStore } = this.props;
    const {
      imgData = '',
      userRoles,
      currentUser,
      currentOrgId,
      benchmarkProviderId,
      dataProviderId,
      email
    } = await fetchUserOrganizationInfo();
    if (userRoles) {
      storeImageData(imgData, currentOrgId, benchmarkProviderId, dataProviderId);
      saveSessionDataToStore({
        userRoles,
        currentUser,
        currentOrgId,
        email,
        benchmarkProviderId,
        dataProviderId
      });
    }
    this.setState({ userRolesLoaded: true });
  }

  setCurrentLanguage = () => {
    const { selectI18nLanguage } = this.props;
    const locale = process.env.REACT_APP_I18N_LANGUAGE;

    if (locale === ENGLISH || locale === DUTCH) {
      i18n.changeLanguage(locale);
      selectI18nLanguage(locale);
    } else {
      // if no language available, setting english as fallback language.
      i18n.changeLanguage(ENGLISH);
      selectI18nLanguage(ENGLISH);
    }
  };

  render() {
    const { userRolesLoaded } = this.state;

    if (!userRolesLoaded) {
      return <Spinner defaultSpinner />;
    }
    return (
      <Suspense fallback={<Spinner defaultSpinner />}>
        <L10NProvider language={i18n.language}>
          <ErrorBoundary>
            <Router basename={BASE_URL_NAME}>
              <Spinner />
              <AppResolver />
            </Router>
          </ErrorBoundary>
        </L10NProvider>
      </Suspense>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators(
    { storeImageData, saveSessionDataToStore, selectI18nLanguage },
    dispatch
  );

export default connect(
  null,
  mapDispatchToProps
)(App);
