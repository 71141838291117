import { SAVE_SESSION_DATA, CLEAR_SESSION_DATA } from 'constants/session';

const initialState = {
  userRoles: [],
  currentUser: {},
  currentOrgId: '',
  email: '',
  benchmarkProviderId: '',
  dataProviderId: ''
};

// Enhance this reducer when handling multiple organizations
export default function sessionReducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_SESSION_DATA:
      return { ...state, ...action.payload };
    case CLEAR_SESSION_DATA:
      return { ...initialState };
    default:
      return state;
  }
}
