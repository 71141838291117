import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import dpTranslations from './dp/en';
import bmsTranslations from './bms/en';
import bmpTranslations from './bmp/en';
import commonTranslations from './common/en';
import bmpNLTranslations from './bmp/nl';
import dpNLTranslations from './dp/nl';
import commonNLTranslations from './common/nl';

const resources = {
  en: {
    ...dpTranslations,
    ...bmsTranslations,
    ...bmpTranslations,
    ...commonTranslations
  },
  nl: {
    ...bmpNLTranslations,
    ...dpNLTranslations,
    ...commonNLTranslations
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next

  // we init with resources
  .init(
    {
      ns: ['dp', 'bmp', 'bms', 'common'],
      defaultNS: 'bms',
      resources,
      react: {
        wait: true
      },
      transSupportBasicHtmlNodes: true,
      fallbackLng: 'en',
      debug: false,
      // have a common namespace used around the full app\
      interpolation: {
        escapeValue: false
      }
    },
    (err, t) => {
      if (err) {
        i18n.reloadResources();
      }
    }
  );

export default i18n;
