/* istanbul ignore file */

import { SAVE_TAXONOMY, DELETE_TAXONOMY } from '../../constants/Taxonomies';

const initialState = {
  name: '',
  language: '',
  taxonomies: [],
  taxonomyId: ''
};

export default function taxonomyReducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_TAXONOMY:
      return { ...state, name: action.name,
        language: action.language};
    case DELETE_TAXONOMY:
      return { ...state, name: '',
        language: ''};
    default:
      return state;
  }
}
