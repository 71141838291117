import {
  SET_SPINNER,
  CLEAN_ALERTS,
  REFORM_BREADCRUMB,
  SHOW_SUCCESS_MESSAGE,
  SHOW_ERROR_MESSAGE,
  SHOW_WARNING_MESSAGE,
  STORE_IMAGE_DATA,
  SHOW_FLASH_MESSAGE,
  SET_LANGUAGE
} from '../constants/Common';

export const showSuccessMessage = (success_message = null, open_flash_message = false) => ({
    type: SHOW_SUCCESS_MESSAGE,
    success_message,
    open_flash_message
  });

export const showErrorMessage = (error_message = null, open_flash_message = false) => ({
    type: SHOW_ERROR_MESSAGE,
    error_message,
    open_flash_message
  });

export const showWarningMessage = (warning_message = null, open_flash_message = false) => ({
    type: SHOW_WARNING_MESSAGE,
    warning_message,
    open_flash_message
  });

export const showFlashMessage = ({
  successMessage = '',
  openFlashMessage = true,
  warningMessage = '',
  errorMessage = '',
  progressMessage = '',
  isIntl = false
}) => ({
    type: SHOW_FLASH_MESSAGE,
    successMessage,
    errorMessage,
    warningMessage,
    openFlashMessage,
    progressMessage,
    isIntl
  });

export function showSpinner({ spinnerText }) {
  return {
    type: SET_SPINNER,
    show_spinner: true,
    apiCounter: 1,
    spinnerText
  };
}

export function closeSpinner() {
  return {
    type: SET_SPINNER,
    show_spinner: false,
    apiCounter: -1
  };
}

export function cleanAlerts() {
  return {
    type: CLEAN_ALERTS,
    error_message: null,
    success_message: null,
    warning_message: null,
    open_flash_message: false
  };
}

export function reformBreadcrumb(canReset = true) {
  return {
    type: REFORM_BREADCRUMB,
    reform_breadcrumb: canReset
  };
}

export function storeImageData(imageData, currentOrgId, benchmarkProviderId, dataProviderId) {
  return {
    type: STORE_IMAGE_DATA,
    image_data: imageData,
    currentOrgId,
    benchmarkProviderId,
    dataProviderId
  };
}

export function selectI18nLanguage(language) {
  return {
    type: SET_LANGUAGE,
    payload: language
  };
}
