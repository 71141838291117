import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Icon as RMWCIcon } from '@rmwc/icon';
import '@rmwc/icon/icon.css';
import '@fortawesome/fontawesome-pro/scss/solid.scss';
import '@fortawesome/fontawesome-pro/scss/regular.scss';
import '@fortawesome/fontawesome-pro/scss/light.scss';
import '@fortawesome/fontawesome-pro/scss/brands.scss';
import '@fortawesome/fontawesome-pro/scss/duotone.scss';
import '@fortawesome/fontawesome-pro/scss/fontawesome.scss';

/**
 * Icon is a presentational component to show an icon defined in font awesome.
 */
export const Icon = ({ name, className, onClick, size, dataTa, iconSet, title, disabled }) => (
  <StyledIcon
    label={name}
    icon={{
      icon: name,
      strategy: 'className',
      basename: iconSet,
      prefix: 'fa-'
    }}
    size={size}
    className={className}
    onClick={onClick}
    data-ta={dataTa}
    title={title}
    disabled={disabled}
  />
);

Icon.defaultProps = {
  iconSet: 'fal',
  size: 'default'
};

Icon.propTypes = {
  /** Additional classes to be added to the icon. */
  className: PropTypes.string,
  /** Name of the icon. This is equal to the name of icon in font-awesome */
  name: PropTypes.string.isRequired,
  /** Callback to be called when clicked on icon */
  onClick: PropTypes.func,
  /** Size of the icon to be displayed. For custom sizes of font awesome we can pass them using className eg: className="fa-4x" */
  size: PropTypes.oneOf(['default', 'xsmall', 'small', 'medium', 'large', 'xlarge']),
  /** attribute value of the custom data-ta attribute that can be used to locate a specific element for testing purposes */
  dataTa: PropTypes.string,
  /** This defines which icon set should the icon be displayed from. Defaulted to font-awesome light (fal)
   * 'fas' - Font awesome solid icons (Icon Ref: https://fontawesome.com/icons?d=gallery&s=solid)
   * 'far' - Font awesome regular icons (Icon Ref: https://fontawesome.com/icons?d=gallery&s=regular)
   * 'fal' - Font awesome light icons (Icon Ref: https://fontawesome.com/icons?d=gallery&s=light)
   * 'fad' - Font awesome duotone icons (Icon Ref: https://fontawesome.com/icons?d=gallery&s=duotone)
   * 'fab' - Font awesome brands icons (Icon Ref: https://fontawesome.com/icons?d=gallery&s=brands)
   */
  iconSet: PropTypes.oneOf(['fas', 'far', 'fal', 'fad', 'fab'])
};

const FONT_SIZES = {
  default: '1rem',
  xsmall: '1.125rem',
  small: '1.25rem',
  medium: '1.5rem',
  large: '2.25rem',
  xlarge: '3rem'
};

const StyledIcon = styled(RMWCIcon)`
  color: ${({ theme }) => theme && theme.iconColor};
  font-size: ${({ size }) => FONT_SIZES[size]};
  cursor: pointer;
  :hover {
    color: ${({ theme }) => theme && theme.primary};
  }
`;
