import { showFlashMessage } from 'actions/common';
import { dispatchFlashMessage, formatDate } from 'helper/utils';
import { store } from 'index';
import * as HTTP from 'services/http';

export const MODIFY_DATA_SOURCE = 'dataset-collection/upload-source';

const GET_ORGANIZATION = 'benchmark/api/data-provider/';
const PER_PAGE = 20;

const childRubricsEndpoint = (rubricId) => `benchmark/api/dataset/${rubricId}/childrubric`;
const DATA_SETS_ENDPOINT = 'benchmark/api/dataset/business-data';
const RECENT_DATA_SETS_ENDPOINT = 'benchmark/api/dataset/recent-datasets';
const DATASET_INTERNAL_EVENTS_ENDPOINT = '/benchmark/api/dataset-internal-event';
const API = 'benchmark/api/dataset-collection/';
const DELETE_API = 'benchmark/api/dataset/delete-businessdata';
const DATA_SET_STATE_ENDPOINT = (id) => `/benchmark/api/dataset-collection/${id}/state`;

const DATA_SET_YEAR_ENDPOINT = (id) => `/benchmark/api/dataset-collection/${id}/year`;

const DATA_SET_STATE_BY_SUBMISSION_ID_ENDPOINT = (submissionId) =>
  `benchmark/api/dataset-collection/${submissionId}/submission-state`;

const LINKED_BENCHMARK_SEGMENTS_ENDPOINT = (id) =>
  `benchmark/api/dataset/${id}/linked-participations`;

const EXCEPTIONS_END_POINT = (id) => `benchmark/api/dataset/${id}/dataset-internal-event`;

const generateDeleteDataSetsRequestUrl = ({
  organizationId,
  dataProviderId,
  registrationId,
  periodKind
}) =>
  `/benchmark/api/dataset/temp-client?dataProviderId=${dataProviderId}&registrationId=${registrationId}&periodKind=${periodKind}`;

const generateSaveAPIRequestURL = (retainClientData) =>
  `benchmark/api/${MODIFY_DATA_SOURCE}?retainClientData=${retainClientData}&isSyncProcess=true`;

const uploadSourceEndpoint = (sourceId, { retainClientData }) =>
  `${API}${sourceId}/uploadData?retainClientData=${retainClientData}`;

const LATEST_PERIOD_ENDPOINT = '/benchmark/api/dataset-collection/';

export function getOrganizationDetails(kvkNumber, params) {
  return HTTP.get(`${GET_ORGANIZATION}${kvkNumber}`, params).then(
    ({
      success = false,
      data,
      message = {},
      errors: { data: { message: errorMessage } = {} } = {}
    } = {}) => {
      if (success) {
        return { success: true, data };
      }

      return { success: false, message: errorMessage || message.message };
    }
  );
}

export function fetchBenchmarkDimensionsList(
  benchmarkProviderId,
  sort = null,
  sortDirection = null
) {
  const params = {
    ...(sort && sortDirection ? { sort: `${sort},${sortDirection}` } : { sort: 'name,ASC' })
  };
  const BENCHMARK_DIMENSIONS = `benchmark/api/data-provider/${benchmarkProviderId}/classifications`;
  return HTTP.get(BENCHMARK_DIMENSIONS, {
    benchmarkProviderId,
    ...params
  }).then(
    ({
      success = false,
      message = {},
      data = {},
      errors: { data: { message: errorMessage } = {} } = {}
    } = {}) => {
      if (success) {
        return { success: true, data };
      }
      dispatchFlashMessage({ errorMessage: message.message || errorMessage, isIntl: true });
      return { success: false };
    }
  );
}

export function fetchChildRubrics({
  rubricId,
  kvkNumber,
  period,
  year,
  periodType,
  retainClientData
}) {
  return HTTP.get(childRubricsEndpoint(rubricId), {
    kvknumber: kvkNumber,
    isChildReq: true,
    period,
    year,
    periodType,
    retainClientData
  }).then(
    ({
      success = false,
      data,
      message = {},
      errors: { data: { message: errorMessage } = {} } = {}
    } = {}) => {
      if (success) {
        return { success: true, data };
      }
      dispatchFlashMessage({
        isIntl: false,
        errorMessage: message.message || errorMessage || 'Internal Server Error'
      });
      return { success: false };
    }
  );
}

export function fetchChildRubricsByDatasetInternalEventId({ rubricId, datasetInternalEventId }) {
  return HTTP.get(
    `${DATASET_INTERNAL_EVENTS_ENDPOINT}/${datasetInternalEventId}/rubrics/${rubricId}`
  ).then(
    ({
      success = false,
      data,
      message = {},
      errors: { data: { message: errorMessage } = {} } = {}
    } = {}) => {
      if (success) {
        return { success: true, data };
      }
      dispatchFlashMessage({
        isIntl: false,
        errorMessage: message.message || errorMessage || 'Internal Server Error'
      });
      return { success: false };
    }
  );
}

export function fetchRecentDataSets({ kvkNumber, retainClientData }) {
  return HTTP.get(RECENT_DATA_SETS_ENDPOINT, {
    kvkNumber,
    retainClientData
  }).then(
    ({
      success = false,
      data,
      message = {},
      errors: { data: { message: errorMessage } = {} } = {}
    } = {}) => {
      if (success) {
        return data;
      }
      dispatchFlashMessage({
        isIntl: false,
        errorMessage: message.message || errorMessage || 'Internal Server Error'
      });
      return { success: false };
    }
  );
}

export function fetchDataSets({
  period,
  year,
  periodType,
  kvkNumber,
  retainClientData,
  applicabilityDimensionCategoryId
}) {
  return HTTP.get(DATA_SETS_ENDPOINT, {
    kvknumber: kvkNumber,
    period,
    year,
    periodType,
    retainClientData,
    classificationCategoryIds: applicabilityDimensionCategoryId
  }).then(
    ({
      success = false,
      data,
      message = {},
      errors: { data: { message: errorMessage } = {} } = {}
    } = {}) => {
      if (success) {
        return { success: true, data };
      }
      dispatchFlashMessage({
        isIntl: false,
        errorMessage: message.message || errorMessage || 'Internal Server Error'
      });
      return { success: false };
    }
  );
}

export function fetchDataSetsByDatasetInternalEventId({ datasetInternalEventId }) {
  return HTTP.get(`${DATASET_INTERNAL_EVENTS_ENDPOINT}/${datasetInternalEventId}/rubrics`).then(
    ({
      success = false,
      data,
      message = {},
      errors: { data: { message: errorMessage } = {} } = {}
    } = {}) => {
      if (success) {
        return { success: true, data };
      }
      dispatchFlashMessage({
        isIntl: false,
        errorMessage: message.message || errorMessage || 'Internal Server Error'
      });
      return { success: false };
    }
  );
}

function generateAPIRequestPayload({
  formattedRubricsData,
  clientDetails,
  zip,
  kvkNumber,
  period,
  year,
  periodType,
  dataSetsClassification
}) {
  const json = {
    classifications: [],
    clientDetails,
    datasetCollection: [
      {
        classificationsOfDataset: dataSetsClassification.classificationsOfDataset,
        id: '',
        rubrics: formattedRubricsData,
        period,
        year,
        periodType
      }
    ],
    providedOn: formatDate(new Date()),
    registrationId: kvkNumber,
    taxonomyId: '',
    fileName: '',
    zip
  };
  return json;
}

export function saveBusinessData(apiParams) {
  const { organizationId, retainClientData, ...rest } = apiParams;
  return HTTP.post(
    generateSaveAPIRequestURL(retainClientData),
    generateAPIRequestPayload(rest),
    {
      disableSpinner: true
    }
  ).then(
    ({
      success = false,
      data,
      message = {},
      errors: { data: { message: errorMessage } = {} } = {}
    } = {}) => {
      if (success) {
        return { success: true, data };
      }
      dispatchFlashMessage({
        isIntl: false,
        errorMessage: message.message || errorMessage || 'Internal Server Error'
      });
      return { success: false };
    }
  );
}

export function uploadFile(body, sourceId, params) {
  return HTTP.post(uploadSourceEndpoint(sourceId, params), body, {
    disableSpinner: true
  }).then(({ success, message = {}, errors = {}, data }) => {
    if (success) {
      return { success: true, data };
    }
    store.dispatch(
      showFlashMessage({
        errorMessage: (errors.data && errors.data.message) || message.message
      })
    );
    return { success: false };
  });
}

export function getXsd(dataSourceId) {
  return HTTP.post(`${API}${dataSourceId}/getXsd`).then(
    ({ success, message = {}, errors = {}, data }) => {
      if (success) {
        return { success: true, data };
      }
      store.dispatch(
        showFlashMessage({
          errorMessage: (errors.data && errors.data.message) || message.message
        })
      );
      return { success: false };
    }
  );
}

export function getState(dataCollectionId) {
  return HTTP.post(`${API}${dataCollectionId}/state`).then(
    ({ success, message = {}, errors = {}, data }) => {
      if (success) {
        return { success: true };
      }
      store.dispatch(
        showFlashMessage({
          errorMessage: (errors.data && errors.data.message) || message.message
        })
      );
      return null;
    }
  );
}

export function deleteDatasetCollection({ period, year, periodType, kvkNumber, isTempClient }) {
  return HTTP.destroy(
    DELETE_API,
    {
      params: { kvknumber: kvkNumber, period, year, periodType, isTempClient }
    },
    { spinnerText: 'Deleting Data ...' }
  ).then(
    ({ success = false, errors: { data: { message: errorMessage } = {} } = {}, message = {} }) => {
      if (success) {
        dispatchFlashMessage({
          isIntl: true,
          successMessage: 'DataSet deleted successfully'
        });
        return { success: true };
      }
      dispatchFlashMessage({
        isIntl: false,
        errorMessage: message.message || errorMessage || 'Internal Server Error'
      });
      return { success: false };
    }
  );
}

export function getStateForDataSets({ id, IsExternalId = false }) {
  const endPoint = IsExternalId
    ? DATA_SET_STATE_BY_SUBMISSION_ID_ENDPOINT(id)
    : DATA_SET_STATE_ENDPOINT(id);
  return HTTP.get(endPoint, undefined, {
    disableSpinner: true
  }).then(
    ({
      success = false,
      data,
      message = {},
      errors: { data: { message: errorMessage } = {} } = {}
    } = {}) => {
      if (success) {
        return { success: true, data: IsExternalId ? data && data.submissionStatus : data };
      }
      return { success: false };
    }
  );
}

export function initiateDataSetsDelete(apiParams) {
  return HTTP.destroy(generateDeleteDataSetsRequestUrl(apiParams), undefined, {}).then(
    ({ success = false } = {}) => {
      if (success) {
        return { success: true };
      }
      return { success: false };
    }
  );
}

export function fetchLatestPeriodForDataSets(dataSetsId) {
  return HTTP.get(DATA_SET_YEAR_ENDPOINT(dataSetsId)).then(
    ({
      success = false,
      data,
      message = {},
      errors: { data: { message: errorMessage } = {} } = {}
    } = {}) => {
      if (success) {
        return { success: true, data };
      }
      dispatchFlashMessage({ errorMessage: errorMessage || message, isIntl: false });
      return { success: false, message: errorMessage || message };
    }
  );
}

export function fetchLatestPeriod(body, dataSourceId) {
  return HTTP.post(`${LATEST_PERIOD_ENDPOINT}${dataSourceId}/getLatestYear`, body).then(
    ({
      success = false,
      message = {},
      data = {},
      errors: { data: { message: errorMessage } = {} } = {}
    } = {}) => {
      if (success) {
        return { success: true, data };
      }
      dispatchFlashMessage({
        errorMessage: message.message || errorMessage,
        isIntl: false
      });
      return { success: false };
    }
  );
}

export function fetchLinkedBenchmarkSegments({ datasetId }) {
  return HTTP.get(LINKED_BENCHMARK_SEGMENTS_ENDPOINT(datasetId)).then(
    ({
      success = false,
      data,
      message = {},
      errors: { data: { message: errorMessage } = {} } = {}
    } = {}) => {
      if (success) {
        return { success: true, data };
      }
      dispatchFlashMessage({
        isIntl: false,
        errorMessage: message.message || errorMessage
      });
      return { success: false };
    }
  );
}

export function fetchExceptionsList({
  datasetId,
  benchmarkSegmentId = null,
  number = 0,
  sort = null,
  sortDirection = null
}) {
  const params = {
    page: number,
    size: PER_PAGE,
    ...(sort && sortDirection ? { sort: `${sort},${sortDirection}` } : { sort: 'occuredOn,ASC' }),
    benchmarkSegmentId
  };
  return HTTP.get(EXCEPTIONS_END_POINT(datasetId), params).then(
    ({
      success = false,
      data,
      message = {},
      errors: { data: { message: errorMessage } = {} } = {}
    } = {}) => {
      if (success) {
        return { success: true, data };
      }
      dispatchFlashMessage({
        isIntl: false,
        errorMessage: message.message || errorMessage
      });
      return { success: false };
    }
  );
}

export const fetchBenchmarkSegments = async () => {
  try {
    const response = await HTTP.get(`${GET_ORGANIZATION}benchmark-segments`);
    return { success: true, data: response.data };
  } catch (error) {
    console.error(error);
    return { success: false };
  }
};
