import {
  SET_DATASETS_DATA,
  SET_PERIOD_DATA,
  SET_SELECTED_YEAR,
  SET_EXCEPTIONS_DATA,
  SET_DATASET_SUBMISSIONS_DATA
} from 'constants/datasetSubmissions';

const initialState = {
  datasetsData: {},
  periodsData: [],
  selectedYearData: {},
  exceptionsData: { exceptionsList: [], datasetSubmissionId: '' },
  datasetsSubmissionsData: {}
};

export default function datasetSubmissionsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DATASETS_DATA:
      return { ...state, datasetsData: action.payload };
    case SET_PERIOD_DATA:
      return { ...state, periodsData: action.payload };
    case SET_SELECTED_YEAR:
      return { ...state, selectedYearData: action.payload };
    case SET_EXCEPTIONS_DATA:
      return { ...state, exceptionsData: action.payload };
    case SET_DATASET_SUBMISSIONS_DATA:
      return { ...state, datasetsSubmissionsData: action.payload };
    default:
      return state;
  }
}
