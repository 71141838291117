const COLORS = {
  ACTIVE_BROWN: '#A18467',
  APP_THEME_BLUE: '#2B4279',
  BACKGROUND_GREY: '#F7F6F5',
  BLACK: '#000000',
  BRIGHT_YELLOW: '#ffcc33',
  COMMON_BROWN: '#DCD1C5',
  CORAL_REEF_GREY: '#C8B7A6',
  DARK_BLUE: '#1E2D53',
  DARK_GREY: '#e5e5e5',
  DARK_PINK: '#f8c4b7',
  DISABLED_BACKGROUND: '#FFF6DD',
  DISABLED_BORDER: '#FFEEBB',
  DISABLED: '#DCD1C5',
  ERROR: '#AF3112',
  FLASH_MESSAGES: '#151927',
  FOCUSED: '#3D5DAA',
  LABEL_BLACK: '#151927',
  LIGHT_BLUE: '#E1E7F4',
  LIGHT_GREEN: '#57B46B',
  GOBLIN_GREEN: '#3E8A4E',
  LIGHT_GREY: '#EFEAE5',
  LIGHT_RED: '#FBDDD6',
  PALE_BLUE: '#F6F8FC',
  PANACHE: '#E4F3E7',
  PERIWINKLE_GRAY: '#C8D2EB',
  RED: '#DD3D17',
  TEXT_BLACK: '#030406',
  WARNING: '#FEEFB3',
  WARNING_RED: '#EB5E3C',
  WHITE: '#FFFFFF',
  LIGHT_ORANGE: '#F1917A'
};
export { COLORS };
