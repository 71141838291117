import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Button as RMWCButton } from '@rmwc/button';
import '@material/button/dist/mdc.button.css';
/**
 * Renders various types of buttons such as primary, secondary, tertiary and danger
 */
export const Button = styled(
  ({ children, buttonType, disabled, dataTa, className, onClick, type }) => (
    <RMWCButton
      disabled={disabled}
      raised={buttonType === 'primary' || buttonType === 'danger'}
      outlined={buttonType === 'secondary'}
      dense={buttonType === 'tertiary'}
      data-ta={dataTa}
      type={type}
      className={className}
      onClick={onClick}
    >
      {children}
    </RMWCButton>
  )
)`
    border: 0;
    box-shadow: none;
    min-width: 90px;
    max-width: 100%;
    text-transform: capitalize;
    :hover, :focus {
        box-shadow: none;
    }
    ${props =>
      props.buttonType === 'primary' &&
      css`
        :hover {
          background-color: ${props.theme.primaryHover};
        }
        :disabled {
          background-color: ${props.theme.primaryDisabled};
          color: ${props.theme.primaryDisabledText};
        }
      `}
    ${props =>
      props.buttonType === 'secondary' &&
      css`
        && {
          border: 1px solid ${props.theme.secondaryBorder};
          background-color: ${props.theme.secondary};
        }
        :disabled {
          background-color: ${props.theme.secondaryDisabled};
          border: 1px solid ${props.theme.secondaryDisabledBorder};
          color: ${props.theme.secondaryDisabledText};
        }
      `}
    ${props =>
      props.buttonType === 'tertiary' &&
      css`
        :hover {
          background-color: ${props.theme.tertiaryHover};
        }
        :disabled {
          color: ${props.theme.tertiaryDisabledText};
        }
      `}
    ${props =>
      props.buttonType === 'danger' &&
      css`
        :not(:disabled) {
          background-color: ${props.theme.danger};
          color: ${props.theme.dangerText};
        }
        :hover {
          background-color: ${props.theme.dangerHover};
        }
        :disabled {
          background-color: ${props.theme.dangerDisabled};
        }
      `}
`;

Button.defaultProps = {
  disabled: false,
  buttonType: 'primary',
  type: 'button'
};

Button.propTypes = {
  /** variant of the button. for example primary secondary tertiary and danger */
  buttonType: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'danger']),
  /** Content specified as children */
  children: PropTypes.node.isRequired,
  /** Whether the button is disabled. */
  disabled: PropTypes.bool.isRequired,
  /** attribute value of the custom data-ta attribute that can be used to locate a specific element for testing purposes */
  dataTa: PropTypes.string,
  /** Callback for when clicked on button */
  onClick: PropTypes.func,
  /** Optional class name to add extra styles */
  className: PropTypes.string,
  /** The type attribute specifies the type of the button for example button, submit and reset */
  type: PropTypes.oneOf(['button', 'submit', 'reset'])
};
