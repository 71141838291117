const initialState = {
  validationResultsData: {}
};

export default function validationResultsReducer(state = initialState, action) {
  if (action.type === 'SET_VALIDATIONS_DATA') {
    return { ...state, validationResultsData: action.payload };
  }
  return state;
}
