import React from 'react';
import PropTypes from 'prop-types';
import RCTooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import './index.css';

/**
 * Component that can be used to display tooltips using rc-tooltip.
 * @see - https://www.npmjs.com/package/rc-tooltip
 *
 */

export const Tooltip = ({
  children,
  overlay,
  placement,
  overlayClassName,
  trigger,
  className,
  mouseEnterDelay
}) => (
  <RCTooltip
    mouseEnterDelay={mouseEnterDelay || 1}
    placement={placement}
    overlay={overlay}
    overlayClassName={overlayClassName}
    trigger={trigger}
    className={className}
  >
    {children}
  </RCTooltip>
);

Tooltip.defaultProps = {
  placement: 'top',
  trigger: 'hover',
  className: '',
  overlayClassName: '',
  mouseEnterDelay: 0
};

Tooltip.propTypes = {
  /** Content specified as children */
  children: PropTypes.node.isRequired,
  /** Optional class name for base element of tooltip to add extra styles */
  className: PropTypes.string,

  mouseEnterDelay: PropTypes.number,
  /** Text to display in tooltip */
  overlay: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  /** Optional class name for overlay of tooltip */
  overlayClassName: PropTypes.string,
  /** Placement of tooltip */
  placement: PropTypes.oneOf([
    'left',
    'right',
    'top',
    'bottom',
    'topLeft',
    'topRight',
    'bottomLeft',
    'bottomRight'
  ]),
  /** Action that shows tooltip */
  trigger: PropTypes.oneOf(['hover', 'click', 'focus'])
};
