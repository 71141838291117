import {
  SET_TAXONOMY_DATA,
  SET_BENCHMARKS_DATA,
  SET_REPORT_SPECIFICATIONS_DATA
} from 'constants/benchmarkSegment';

const initialState = {
  currentSegmentData: {},
  reportSpecificationsList: [],
  taxonomiesList: {},
  businessClassificationsList: [],
  benchmarkCriteria: [],
  reportSpecificationsData: {
    reportSpecifications: [],
    number: 0,
    totalElements: 0,
    segmentId: ''
  },
  benchmarksData: { benchmarks: [], number: 0, totalElements: 0, segmentId: '' }
};

export default function benchmarkSegmentReducer(state = initialState, action) {
  switch (action.type) {
    case SET_TAXONOMY_DATA:
      return { ...state, taxonomiesList: action.payload };

    case SET_BENCHMARKS_DATA:
      return { ...state, benchmarksData: action.payload };

    case SET_REPORT_SPECIFICATIONS_DATA:
      return { ...state, reportSpecificationsData: action.payload };

    default:
      return state;
  }
}
