export const BMS_ADMIN = 'BMS_ADMIN';
export const BMS_CONTENT_MANAGER = 'BMS_CONTENT_MANAGER';
export const BENCHMARK_DEVELOPER = 'BENCHMARK_DEVELOPER';
export const BENCHMARK_REPORT_DEVELOPER = 'BENCHMARK_REPORT_DEVELOPER';
export const BENCHMARK_ADMIN = 'BENCHMARK_ADMIN';
export const DATA_PROVIDER_ADMIN = 'DATA_PROVIDER_ADMIN';
export const BENCHMARK_REQUESTOR = 'BENCHMARK_REQUESTOR';
export const DATA_SUBMITTER = 'DATA_SUBMITTER';
export const BENCHMARK_INVESTIGATOR = 'BENCHMARK_INVESTIGATOR';

export const BMS_PORTAL_ROLES = [BMS_CONTENT_MANAGER, BMS_ADMIN];
export const BMP_PORTAL_ROLES = [
  BENCHMARK_DEVELOPER,
  BENCHMARK_REPORT_DEVELOPER,
  BENCHMARK_ADMIN,
  BENCHMARK_INVESTIGATOR
];
export const DP_PORTAL_ROLES = [DATA_PROVIDER_ADMIN, BENCHMARK_REQUESTOR, DATA_SUBMITTER];
export const DP_ROLES_LIST = {
  DATA_PROVIDER_ADMIN: 'Data Provider Admin',
  DATA_SUBMITTER: 'Data Submitter',
  BENCHMARK_REQUESTOR: 'Benchmark Requestor'
};
