import { store } from 'index';
import * as Sentry from '@sentry/browser';
import { updateBreadcrumb } from 'actions/breadcrumbs/BreadcrumbsActions';
import { axios } from 'services/BmsAuthService';
import { cleanAlerts, closeSpinner, showSpinner } from 'actions/common';
import { authProvider } from 'AuthProvider';
import i18n from 'i18n/i18nConfig';
import { MODIFY_DATA_SOURCE } from 'containers/DPPortal/DataEntry/actions';
import { BENCHMARKS_FILTER_ENDPOINT } from 'containers/DPPortal/RequestBenchmark/actions';
import { InteractionRequiredAuthError } from '@azure/msal-browser';

export const BREADCRUMB_FETCH_URL = 'benchmark/api/breadcrumb';

const ERROR_RESPONSE = {
  success: false,
  errors: { data: { message: i18n.t('common:genericServerError') } }
};

const BREADCRUMB_API_EXCLUDE_LIST = [
  BENCHMARKS_FILTER_ENDPOINT,
  MODIFY_DATA_SOURCE,
  BREADCRUMB_FETCH_URL
];
const ALLOWED_BREADCRUMB_UPDATE_METHODS = ['put', 'post'];

const canUpdateBreadCrumb = (method, endpoint) => {
  const canAllowUpdate = ALLOWED_BREADCRUMB_UPDATE_METHODS.includes(method);
  const canAcceptUpdate = !BREADCRUMB_API_EXCLUDE_LIST.find((item) => endpoint.includes(item));
  return canAllowUpdate && canAcceptUpdate;
};

const corsHeaders = {
  'Access-Control-Allow-Origin': '*', // NOSONAR
  'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
};

async function callApi(
  endpoint,
  method,
  body = {},
  { disableSpinner = false, customHeaders = {}, spinnerText, source = {} } = {}
) {
  const defaultHeaders = await getCommonHeaders();
  const { common: { success_message } = {} } = store.getState();

  if (!success_message) store.dispatch(cleanAlerts()); // clear alerts
  handleSpinner(disableSpinner, true, spinnerText); // display spinner

  return axios({
    method,
    url: endpoint,
    headers: { ...defaultHeaders, ...customHeaders, ...corsHeaders },
    data: body,
    params: body && body.params,
    cancelToken: source.token
  })
    .then(({ data = {}, message = '' }) => {
      if (canUpdateBreadCrumb(method, endpoint)) {
        updateBreadcrumb();
      }
      handleSpinner(disableSpinner, false); // hide spinner
      return { success: true, data, message };
    })
    .catch((error) => {
      handleSpinner(disableSpinner, false); // hide spinner
      if (error.response) return handleErrorResponse(error.response, error); // handles Error response
      if (error.request) return ERROR_RESPONSE; // The request was made but no response was received

      return ERROR_RESPONSE; // Something happened in setting up the request that triggered an Error
    });
}

// Log Internal server error from API to Sentry with endpoint and response
function logSentry(error, data, endpoint) {
  if (process.env.NODE_ENV === 'production') {
    // eslint-disable-next-line no-param-reassign
    error.name = 'API returned Internal Server Error';
    error.message = JSON.stringify({ endpoint, message: data.message });
    Sentry.withScope((scope) => {
      scope.setExtra('endpoint', endpoint);
      Object.keys(data).forEach((key) => {
        scope.setExtra(key, data[key]);
      });
      Sentry.captureException(error);
    });
  }
}

function handleErrorResponse(response, error) {
  /*
    This code block will be executed if the request was made and the server
    responded with a status code that falls out of the range of 2xx (i.e. 3xx and 4xx etc.)
  */
  if (response.status === 401) {
    // Invalidate session
  }

  if (response.status === 422) {
    return { success: false, message: response.data };
  }

  if (response.status === 500) {
    logSentry(error, response.data, response.config.url);
  }
  // store.dispatch(showFlashMessage(response.data.message));
  return { success: false, errors: response };
}

function handleSpinner(disableSpinner, displaySpinner = true, spinnerText) {
  if (!disableSpinner) {
    return displaySpinner
      ? store.dispatch(showSpinner({ spinnerText }))
      : store.dispatch(closeSpinner());
  }
  return null;
}

export async function getCommonHeaders() {
  const accounts = authProvider.getAllAccounts();
  let returnHeaders = {};
  if (accounts?.length > 0) {
    const request = {
      scopes: [process.env.REACT_APP_ADB2C_SCOPES],
      account: accounts[0]
    };

    await authProvider
      .acquireTokenSilent(request)
      .then((response) => {
        returnHeaders = { headers: corsHeaders, Authorization: `Bearer ${response.idToken}` };
      })
      .catch((error) => {
        // acquireTokenSilent can fail for a number of reasons, fallback to interaction
        if (error instanceof InteractionRequiredAuthError) {
          authProvider.acquireTokenPopup(request).then((response) => {
            returnHeaders = { headers: axios.headers, Authorization: `Bearer ${response.idToken}` };
          });
        }
      });
  }
  return returnHeaders;
}

export function get(url, body, options) {
  const params = { params: body, data: null };
  return callApi(url, 'get', params, options);
}

export function post(url, body, options) {
  return callApi(url, 'post', body, options);
}

export function put(url, body, options) {
  return callApi(url, 'put', body, options);
}

export function destroy(url, body, options) {
  return callApi(url, 'delete', body, options);
}

export async function downloadFile(url, body, methodType) {
  const headers = await getCommonHeaders();
  handleSpinner(false, true, '');

  return axios(url, { responseType: 'blob', headers, method: methodType, data: body })
    .then(({ headers = {}, message = {}, data = {} } = {}) => {
      handleSpinner(false, false, '');
      if (data) {
        const [, filename] =
          !!headers['content-disposition'] && headers['content-disposition'].split('filename=');
        return { success: true, data, filename };
      } else if (message) {
        return {
          success: false,
          message: message.message || ERROR_RESPONSE.errors.data.message
        };
      }
      return { success: false };
    })
    .catch((error) => {
      handleSpinner(false, false, '');
      if (error.response) return handleErrorResponse(error.response, error); // handles Error response

      return ERROR_RESPONSE; // The request was made but no response was received or Something happened in setting up the request that triggered an Error
    });
}
