import * as HTTP from 'services/http';
import { store } from 'index';
import { showErrorMessage, showFlashMessage } from 'actions/common';
import { axios } from 'services/BmsAuthService';

export const BENCHMARKS_FILTER_ENDPOINT = 'benchmark/api/benchmark/filter';
const CATEGORY_END_POINT = 'benchmark/api/classification-category/';
const CLASSIFICATION_CATEGORIES_END_POINT = 'benchmark/api/business-classification/';
const REPORT_TYPE_ENDPOINT = 'benchmark/api/report-type';
const REPORT_SPECIFICATIONS_ENDPOINT = (id) => `benchmark/api/benchmark/${id}/filter/report`;
const REQUEST_REPORT_ENDPOINT = 'benchmark/api/benchmark-delivery';
const RUBRICS_LIST_ENDPOINT = (id, reportSpecificationId) =>
  `benchmark/api/benchmark/${id}/rescaling-candidates/${reportSpecificationId}/report-validations`;
const ADMINISTRATION_ENDPOINT = 'benchmark/api/administration';
const RUBRIC_DIMENSION_CATEGORIES = (id) =>
  `benchmark/api/benchmark-criterions/${id}/rubric-categories`;

const GET_APPLICABILITY_DIMENSION = (kvkNumber) =>
  `benchmark/api/data-provider/${kvkNumber}/applicability-dimension`;
const GET_APPLICABILITY_CATEGORY = (kvkNumber) =>
  `benchmark/api/data-provider/${kvkNumber}/classification-categories`;

const GET_MULTIPLE_BENCHMARKS_MEMBERSHIP_SIZE = `benchmark/api/benchmark/membership`;

const PER_PAGE = 30;
const MAX_ELEMENTS = 1000;

export function fetchBenchmarkDimensionsList(
  benchmarkProviderId,
  kvkNumber = null,
  retainClientData = null,
  showDeprecated = true,
  periodKind = null
) {
  const params = {
    size: MAX_ELEMENTS,
    sort: 'name,ASC'
  };
  const BENCHMARK_DIMENSIONS = 'benchmark/api/benchmark-dimension';
  return HTTP.get(BENCHMARK_DIMENSIONS, {
    benchmarkProviderId,
    clientId: kvkNumber,
    retainClientData,
    showDeprecated,
    periodKind,
    ...params
  }).then(
    ({
      success = false,
      message = {},
      data = {},
      errors: { data: { message: errorMessage } = {} } = {}
    } = {}) => {
      if (success) {
        return { success: true, data };
      }
      store.dispatch(showErrorMessage(message.message || errorMessage, true));
      return { success: false };
    }
  );
}

export function fetchCategoriesForBenchmarkClassification({
  dimensionId,
  page = 0,
  showDeprecated = true
}) {
  const params = {
    page,
    size: PER_PAGE,
    sort: 'name,ASC',
    showDeprecated
  };
  return HTTP.get(`${CLASSIFICATION_CATEGORIES_END_POINT}${dimensionId}/categories`, params).then(
    ({
      success = false,
      message = {},
      data = {},
      errors: { data: { message: errorMessage } = {} } = {}
    } = {}) => {
      if (success) {
        return { success: true, data };
      }
      store.dispatch(showErrorMessage(message.message || errorMessage, true));
      return { success: false };
    }
  );
}

export function fetchCategoriesForRubricDimensions({
  kvkNumber,
  retainClientData,
  periodKind,
  period,
  year
}) {
  const params = {
    retainClientData,
    periodKind,
    period,
    year
  };
  return HTTP.get(RUBRIC_DIMENSION_CATEGORIES(kvkNumber), params).then(
    ({
      success = false,
      message = {},
      data = {},
      errors: { data: { message: errorMessage } = {} } = {}
    } = {}) => {
      if (success) {
        return { success: true, data };
      }
      store.dispatch(showErrorMessage(message.message || errorMessage, true));
      return { success: false };
    }
  );
}

export function fetchcategoryHierarchy({ categoryId }) {
  return HTTP.get(`${CATEGORY_END_POINT}${categoryId}/hierarchy`).then(
    ({
      success = false,
      message = {},
      data = {},
      errors: { data: { message: errorMessage } = {} } = {}
    } = {}) => {
      if (success) {
        return { success: true, data };
      }
      store.dispatch(showErrorMessage(message.message || errorMessage, true));
      return { success: false };
    }
  );
}

export function fetchChildCategories(id) {
  const params = {
    isChildRequired: true
  };
  return HTTP.get(CATEGORY_END_POINT + id, params).then(
    ({
      success = false,
      message = {},
      data = {},
      errors: { data: { message: errorMessage } = {} } = {}
    } = {}) => {
      if (success) {
        return { success: true, data };
      }
      store.dispatch(showErrorMessage(message.message || errorMessage, true));
      return { success: false };
    }
  );
}

export function fetchReportTypeData(supportsReference) {
  const params = supportsReference ? {} : { supportsReference };
  return HTTP.get(REPORT_TYPE_ENDPOINT, params).then(
    ({
      success = false,
      message = {},
      data = {},
      errors: { data: { message: errorMessage } = {} } = {}
    } = {}) => {
      if (success) {
        return { success: true, data };
      }
      store.dispatch(showErrorMessage(message.message || errorMessage, true));
      return { success: false };
    }
  );
}

export function fetchBenchmarksList({ dimensionFiltersArray, ...apiParams }) {
  return HTTP.post(BENCHMARKS_FILTER_ENDPOINT, {
    dimensionFilters: dimensionFiltersArray,
    ...apiParams,
    size: MAX_ELEMENTS
  }).then(
    ({
      success = false,
      message = {},
      data = {},
      errors: { data: { message: errorMessage } = {} } = {}
    } = {}) => {
      if (success) {
        return { success: true, data };
      }
      store.dispatch(showErrorMessage(message.message || errorMessage, true));
      return { success: false };
    }
  );
}

export function fetchReportSpecifications(data) {
  return HTTP.get(REPORT_SPECIFICATIONS_ENDPOINT(data.benchmarkId), data).then(
    ({
      success = false,
      message = {},
      data = {},
      errors: { data: { message: errorMessage } = {} } = {}
    } = {}) => {
      if (success) {
        return { success: true, data };
      }
      store.dispatch(showErrorMessage(message.message || errorMessage, true));
      return { success: false };
    }
  );
}

export async function downloadReport(reportTypeId, apiParams) {
  const state = store.getState();
  const URL = process.env.REACT_APP_SERVER_URL;

  return HTTP.downloadFile(
    `${URL}benchmark/api/benchmark-delivery/calculation-available`,
    apiParams,
    'POST'
  )
    .then(async (response) => {
      const { data = {}, success = false, filename = '', message = {} } = response;
      let errorMessage = {};
      if (data && success) {
        return { success: true, data, filename };
      } else {
        const blob = response.errors.data;
        const errorResponse = JSON.parse(await blob.text());
        errorMessage = errorResponse.message;
        return { success: false, data, filename, error: message.message || errorMessage };
      }
    })
    .catch((err) => ({ success: false, status: err.response.status }));
}

export function downloadFile({ data, reportName }) {
  try {
    const blob = new Blob([data], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = reportName;
    link.click();
    link.remove();
    URL.revokeObjectURL(url);
    return { success: true };
  } catch (err) {
    return { success: false };
  }
}

export function requestReportForBenchmark(apiParams) {
  return HTTP.post(REQUEST_REPORT_ENDPOINT, apiParams).then((response) => {
    const { data = {}, success = false, message = {} } = response;
    if (success) {
      return { success: true, data };
    } else {
      return { success: false, data, error: message.message };
    }
  });
}

export function createBenchmark(apiParams) {
  return HTTP.post(`${REQUEST_REPORT_ENDPOINT}/create-benchmark`, apiParams).then(
    ({
      success = false,
      message = {},
      data = {},
      errors: { data: { message: errorMessage } = {} } = {}
    } = {}) => {
      if (success) {
        return { success: true, data };
      }
      store.dispatch(showErrorMessage(message.message || errorMessage, true));
      return { success: false };
    }
  );
}

export function fetchApplicabilityDimensionForClient(kvkNumber, apiParams) {
  return HTTP.get(GET_APPLICABILITY_DIMENSION(kvkNumber), apiParams).then(
    ({
      success = false,
      message = {},
      data = {},
      errors: { data: { message: errorMessage } = {} } = {}
    } = {}) => {
      if (success) {
        return { success: true, data };
      }
      store.dispatch(showErrorMessage(message.message || errorMessage, true));
      return { success: false };
    }
  );
}

export function fetchApplicabilityCategories(kvkNumber, apiParams) {
  return HTTP.get(GET_APPLICABILITY_CATEGORY(kvkNumber), apiParams).then(
    ({
      success = false,
      message = {},
      data = {},
      errors: { data: { message: errorMessage } = {} } = {}
    } = {}) => {
      if (success) {
        return { success: true, data };
      }
      store.dispatch(showErrorMessage(message.message || errorMessage, true));
      return { success: false };
    }
  );
}

export function fetchRubricsList({ benchmarkId, reportSpecificationId, showDeprecated = true }) {
  return HTTP.get(RUBRICS_LIST_ENDPOINT(benchmarkId, reportSpecificationId), {
    showDeprecated
  }).then(
    ({
      success = false,
      message = {},
      data = {},
      errors: { data: { message: errorMessage } = {} } = {}
    } = {}) => {
      if (success) {
        return { success: true, data };
      }
      store.dispatch(showErrorMessage(message.message || errorMessage, true));
      return { success: false };
    }
  );
}

export function createClient(apiParams) {
  return HTTP.post(ADMINISTRATION_ENDPOINT, apiParams).then(
    ({
      success = false,
      message = {},
      data = {},
      errors: { data: { message: errorMessage } = {} } = {}
    } = {}) => {
      if (success) {
        store.dispatch(
          showFlashMessage({
            isIntl: true,
            successMessage: 'Client saved successfully'
          })
        );
        return { success: true, data };
      }
      store.dispatch(showErrorMessage(errorMessage || message.message, true));
      return { success: false };
    }
  );
}

export function fetchTempClient(apiParams) {
  return HTTP.get(`${ADMINISTRATION_ENDPOINT}/tempClientExists`, apiParams).then(
    ({
      success = false,
      message = {},
      data = {},
      errors: { data: { message: errorMessage } = {} } = {}
    } = {}) => {
      if (success) {
        return { success: true, data };
      }
      store.dispatch(showErrorMessage(errorMessage || message.message, true));
      return { success: false };
    }
  );
}

const { CancelToken } = axios;
export const source = CancelToken.source();

export const getMembershipSizeOfMultipleBenchmarks = async (benchmarkIdsList = []) => {
  const body = {
    benchmarkIdsList: benchmarkIdsList.toString()
  };
  const response = await HTTP.get(GET_MULTIPLE_BENCHMARKS_MEMBERSHIP_SIZE, body, {
    disableSpinner: true,
    source
  });
  const { success = false, data = {} } = response;
  return { success, data };
};
