import {
  SET_PERIOD_SPECIFICATION_DATA,
  SET_SELECTION_CRITERIA_DATA,
  SET_REPORT_TYPES_DATA,
  SET_BENHCMARK_MEASURES_DATA,
  SET_BMP_USERS_DATA,
  SET_MY_ACCOUNT_DATA,
  SET_LANGUAGES_DATA
} from 'constants/settings';

const initialState = {
  periodSpecificationData: {},
  selectionCriteriaData: {},
  reportTypesData: {},
  benchmarkMeasuresData: {},
  bmpUsersData: { users: [], totalElements: 0, number: 0 },
  myAccountData: {
    roleList: []
  },
  languagesData: []
};

export default function settingsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_PERIOD_SPECIFICATION_DATA:
      return { ...state, periodSpecificationData: action.payload };
    case SET_SELECTION_CRITERIA_DATA:
      return { ...state, selectionCriteriaData: action.payload };
    case SET_REPORT_TYPES_DATA:
      return { ...state, reportTypesData: action.payload };
    case SET_BENHCMARK_MEASURES_DATA:
      return { ...state, benchmarkMeasuresData: action.payload };
    case SET_BMP_USERS_DATA:
      return { ...state, bmpUsersData: action.payload };
    case SET_MY_ACCOUNT_DATA:
      return { ...state, myAccountData: action.payload };
    case SET_LANGUAGES_DATA:
      return { ...state, languagesData: action.payload };
    default:
      return state;
  }
}
