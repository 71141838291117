import { SET_BENCHMARK_DELIVERIES_DATA, SET_EXCEPTIONS_DATA } from 'constants/benchmarkDeliveries';

const initialState = {
  benchmarkDeliveryData: {},
  exceptionsData: {
    exceptionsList: [],
    page: {},
    benchmarkDeliveryId: ''
  }
};

export default function benchmarkDeliveriesReducer(state = initialState, action) {
  switch (action.type) {
    case SET_BENCHMARK_DELIVERIES_DATA:
      return { ...state, benchmarkDeliveryData: action.payload };
    case SET_EXCEPTIONS_DATA:
      return { ...state, exceptionsData: action.payload };
    default:
      return state;
  }
}
