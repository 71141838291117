import { PublicClientApplication } from '@azure/msal-browser';

// Msal Configurations
export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_ADB2C_CLIENT_ID,
    authority: process.env.REACT_APP_ADB2C_AUTHORITY,
    redirectUri: `${process.env.REACT_APP_WEB_PORTAL_URL}${process.env.REACT_APP_WEB_PORTAL_BASE_URL_NAME}`,
    knownAuthorities: [process.env.REACT_APP_ADB2C_KNOWN_AUTHORITIES]
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false
  }
};

const authProvider = new PublicClientApplication(msalConfig);

authProvider.handleRedirectPromise().catch(() => authProvider.loginRedirect(msalConfig));

export { authProvider };
