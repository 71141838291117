import { InteractionType } from '@azure/msal-browser';
import {
  AuthenticatedTemplate,
  MsalAuthenticationTemplate,
  MsalProvider,
  UnauthenticatedTemplate
} from '@azure/msal-react';
import { handleSessionExpiry } from 'services/BmsAuthService';
import * as Sentry from '@sentry/browser';
import { authProvider } from 'AuthProvider';
import Spinner from 'components/common/Spinner';
import { Button } from 'lib/button/button';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { sessionService } from 'redux-react-session';
import configureStore from 'store/Store';
import App from './App';
import './index.css';

export const store = configureStore();

// Init the session service
sessionService.initSessionService(store, { refreshOnCheckAuth: false });

// if (process.env.NODE_ENV === 'development') {
//   const { whyDidYouUpdate } = require('why-did-you-update');
//   whyDidYouUpdate(React);
// }

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN
  });
}

const accounts = authProvider.getAllAccounts();

const request = {
  scopes: [process.env.REACT_APP_ADB2C_SCOPES],
  account: accounts[0]
};
ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={authProvider} store={store}>
      <AuthenticatedTemplate>
        <Provider store={store}>
          <App />
        </Provider>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate />
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={request}
      >
        <Provider store={store}>
          <Spinner show_spinner="true" />
        </Provider>
      </MsalAuthenticationTemplate>
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

handleSessionExpiry();
