import {
  SHOW_SUCCESS_MESSAGE,
  SHOW_ERROR_MESSAGE,
  SHOW_WARNING_MESSAGE,
  SET_SPINNER,
  CLEAN_ALERTS,
  REFORM_BREADCRUMB,
  STORE_IMAGE_DATA,
  SHOW_FLASH_MESSAGE,
  SET_LANGUAGE
} from '../constants/Common';

const initialState = {
  error_message: null,
  success_message: null,
  warning_message: null,
  open_flash_message: false,
  show_spinner: false,
  reform_breadcrumb: false,
  image_data: '',
  currentOrgId: '',
  benchmarkProviderId: '',
  dataProviderId: '',
  progressMessage: '',
  isIntl: false,
  apiCounter: 0,
  spinnerText: '',
  language: ''
};

export default function commonReducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_SUCCESS_MESSAGE:
      return { ...state, success_message: action.success_message,
        open_flash_message: action.open_flash_message};
    case SHOW_ERROR_MESSAGE:
      return { ...state, error_message: action.error_message,
        open_flash_message: action.open_flash_message};
    case SHOW_WARNING_MESSAGE:
      return { ...state, warning_message: action.warning_message,
        open_flash_message: action.open_flash_message};
    case SHOW_FLASH_MESSAGE:
      return { ...state, success_message: action.successMessage,
        error_message: action.errorMessage,
        warning_message: action.warningMessage,
        open_flash_message: action.openFlashMessage,
        progressMessage: action.progressMessage,
        isIntl: action.isIntl};
    case SET_SPINNER:
      return { ...state, show_spinner: action.show_spinner,
        apiCounter: state.apiCounter + action.apiCounter,
        spinnerText: action.spinnerText};
    case CLEAN_ALERTS:
      return { ...state, success_message: action.success_message,
        error_message: action.error_message,
        warning_message: action.warning_message,
        open_flash_message: action.open_flash_message,
        progressMessage: ''};
    case REFORM_BREADCRUMB:
      return { ...state, reform_breadcrumb: action.reform_breadcrumb};
    case STORE_IMAGE_DATA:
      return {
        ...state,
        image_data: action.image_data,
        currentOrgId: action.currentOrgId,
        benchmarkProviderId: action.benchmarkProviderId,
        dataProviderId: action.dataProviderId
      };
    case SET_LANGUAGE:
      return { ...state, language: action.payload };
    default:
      return state;
  }
}
