export const SET_SPINNER = 'SET_SPINNER';
export const CLEAN_ALERTS = 'CLEAN_ALERTS';
export const REFORM_BREADCRUMB = 'REFORM_BREADCRUMB';
export const SHOW_SUCCESS_MESSAGE = 'SHOW_SUCCESS_MESSAGE';
export const SHOW_ERROR_MESSAGE = 'SHOW_ERROR_MESSAGE';
export const SHOW_WARNING_MESSAGE = 'SHOW_WARNING_MESSAGE';
export const STORE_IMAGE_DATA = 'STORE_IMAGE_DATA';
export const SHOW_FLASH_MESSAGE = 'SHOW_FLASH_MESSAGE';
export const BMS_ROLES = ['BMS_ADMIN', 'BMS_CONTENT_MANAGER'];
export const BENCHMARK_PROVIDER_ROLES = [
  'BENCHMARK_ADMIN',
  'BENCHMARK_DEVELOPER',
  'BENCHMARK_REPORT_DEVELOPER'
];
export const DATA_PROVIDER_ADMIN = 'DATA_PROVIDER_ADMIN';
export const DATA_PROVIDER_ROLES = ['DATA_PROVIDER_ADMIN', 'BENCHMARK_REQUESTOR', 'DATA_SUBMITTER'];
export const BASE_URL_NAME = process.env.REACT_APP_WEB_PORTAL_BASE_URL_NAME || '';
export const IN_DEVELOPMENT = 'Development';
export const PUBLISHED = 'Published';
export const PROCESSING = 'Processing';
export const INVALID = 'Invalid';
export const MIN_VALUE_OF_DOUBLE_TYPE = -1.7976931348623157e308;
export const MAX_VALUE_OF_DOUBLE_TYPE = 1.7976931348623157e308;
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const ENGLISH = 'en';
export const DUTCH = 'nl';
export const DATE_FORMAT = 'Ppp';
export const JSON = 'JSON';

export const DAY = 'DAY';
export const WEEK = 'WEEK';
export const MONTH = 'MONTH';
export const YEAR = 'YEAR';
export const QUARTER = 'QUARTER';
export const FOURWEEK = 'FOURWEEK';

export const RED = 'red';
export const BLUE = 'blue';
export const GREEN = 'green';
export const GREY = 'grey';
