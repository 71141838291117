import {
  SET_DATA_SOURCES_DATA,
  SET_LINKED_DATA_SOURCES_DATA,
  SET_ORG_INFO_DATA,
  SET_ORG_LOGO_DATA
} from 'constants/dpSettings';

const initialState = {
  orgInfoData: {},
  orgLogoData: '',
  linkedDataSourcesData: [],
  dataSourcesData: []
};

export default function dpSettingsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_LINKED_DATA_SOURCES_DATA:
      return { ...state, linkedDataSourcesData: action.payload };
    case SET_DATA_SOURCES_DATA:
      return { ...state, dataSourcesData: action.payload };
    case SET_ORG_INFO_DATA:
      return { ...state, orgInfoData: action.payload };
    case SET_ORG_LOGO_DATA:
      return { ...state, orgLogoData: action.payload };
    default:
      return state;
  }
}
