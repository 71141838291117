import { store } from 'index';
import { reformBreadcrumb } from 'actions/common';
import * as HTTP from 'services/http';

export const fetchBreadcrumbInfo = async (body) => {
  const breadcrumbMap = {};
  body.forEach((element) => {
    breadcrumbMap[element.type] = element.id;
  });
  const response = await HTTP.get(HTTP.BREADCRUMB_FETCH_URL, breadcrumbMap, {
    disableSpinner: true
  });
  return response;
};

export function updateBreadcrumb() {
  store.dispatch(reformBreadcrumb());
}
