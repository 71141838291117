import { sessionService } from 'redux-react-session';
import SessionHelper from 'services/session';
import * as HTTP from 'services/http';
import { SAVE_SESSION_DATA, CLEAR_SESSION_DATA } from 'constants/session';
import { DP_PORTAL_ROLES } from 'constants/roles';
import { authProvider } from 'AuthProvider';

export const fetchLoginInfo = () => {
  const accounts = authProvider.getAllAccounts();
  if (accounts?.length > 0) {
    const { idTokenClaims: { email = '' } = {} } = accounts[0];
    const loginUrl = `benchmark/api/user/sign-in/${email}`;

    return HTTP.get(loginUrl).then(({ success = false, data = {} }) => {
      if (success) {
        const { userOrganizationList, userRoles = [] } = data;
        return {
          success: true,
          userOrganizationList,
          userRoles: userRoles.map((role) => role.type),
          email
        };
      }
      return { success: false, data: {} };
    });
  }
  return { success: false, data: {} };
};

export const setRolesInfo = (roles) => ({
  isBMSAdmin: roles.indexOf('BMS_ADMIN') !== -1,
  isBMSContentManager: roles.indexOf('BMS_CONTENT_MANAGER') !== -1,
  isAdmin: roles.indexOf('BENCHMARK_ADMIN') !== -1,
  isBenchmarkDeveloper: roles.indexOf('BENCHMARK_DEVELOPER') !== -1,
  isBenchmarkReportDeveloper: roles.indexOf('BENCHMARK_REPORT_DEVELOPER') !== -1,
  isDataProviderAdmin: roles.indexOf('DATA_PROVIDER_ADMIN') !== -1,
  isBenchmarkRequestor: roles.indexOf('BENCHMARK_REQUESTOR') !== -1,
  isDataSubmitter: roles.indexOf('DATA_SUBMITTER') !== -1,
  isBenchmarkInvestigator: roles.indexOf('BENCHMARK_INVESTIGATOR') !== -1
});

export const fetchUserOrganizationInfo = async () => {
  sessionService
    .loadUser()
    .then(({ currentOrgLogo = '', userRoles = [] }) => {
      if (currentOrgLogo && userRoles) return { imgData: currentOrgLogo, userRoles };
      return null;
    })
    .catch((error) => { });

  const { userOrganizationList = [], userRoles = [], ...rest } = await fetchLoginInfo();
  const currentOrgId = userOrganizationList[0] && userOrganizationList[0].userOrganizationId;
  const { benchmarkProviderId, dataProviderId } = userOrganizationList[0] || {};
  const isBenchmarkProviderLogo = DP_PORTAL_ROLES.includes(userRoles[0]);
  const imgResponse = await fetchOrgLogo(isBenchmarkProviderLogo);
  const currentUser = setRolesInfo(userRoles);
  if (userOrganizationList[0]) {
    await sessionService.saveUser({
      userOrganizationList,
      benchmarkProviderId,
      dataProviderId,
      userRoles,
      currentOrgId,
      currentOrgLogo: imgResponse.data
    });
  }
  return {
    imgData: imgResponse.data,
    userRoles,
    currentOrgId,
    currentUser,
    benchmarkProviderId,
    dataProviderId,
    ...rest
  };
};

const userChannel = new BroadcastChannel('user');

export const logout = async () => {
  const { currentOrgId, email } = await fetchUserOrganizationInfo();

  const promise = new Promise((resolve, reject) => {
    try {
      sessionService.deleteUser();
      // clear the session helper
      SessionHelper.removeExpiry(); // clear the expiry value from storage
      SessionHelper.stopExpiryTimeout(); // stop the timeout function
      authProvider.logout(); // logout the user
      // await localStorage.clear();
      sessionStorage.clear();
      userChannel.postMessage({
        payload: {
          type: 'SIGN_OUT',
          email,
          currentOrgId
        }
      });
      resolve();
    } catch (err) {
      reject(err);
    }
  });
  return promise.catch((err) => {
    throw err;
  });
};

userChannel.onmessage = async (message) => {
  const { currentOrgId: orgId, email: emailId } = await fetchUserOrganizationInfo();
  const {
    data: {
      payload: { currentOrgId, email, type }
    }
  } = message;
  if (type === 'SIGN_OUT' && orgId === currentOrgId && emailId === email) {
    logout();
  }
};

export const validateUser = (userRoles, allowedRoles) =>
  userRoles && userRoles.some((role) => allowedRoles.indexOf(role) === -1);

export function fetchOrgLogo(isBenchmarkProviderLogo = false) {
  const params = {
    isBenchmarkProviderLogo
  };
  const logoUrl = `benchmark/api/user-organization/logo`;
  return HTTP.get(logoUrl, params).then((response) => {
    if (response && response.success) {
      return { success: true, data: response.data };
    }
    return { success: false };
  });
}

export function saveSessionDataToStore(userData) {
  return {
    type: SAVE_SESSION_DATA,
    payload: userData
  };
}

export function clearSessionDataFromStore() {
  return {
    type: CLEAR_SESSION_DATA
  };
}
